<template>
  <div class="btn_wrap">
    <span>Contact Me</span>
    <div class="container">
      <a href="https://www.facebook.com/houtkyso.panha.9/" target="blank">
        <i class="fa-brands fa-facebook" style="color: #18629a"></i>
      </a>

      <a href="https://www.instagram.com/houtkysopanha/" target="blank">
        <i class="fa-brands fa-instagram" style="color: #c31d1d"></i>
      </a>

      <a href="https://t.me/houtKysopanha" target="blank">
        <i class="fa-brands fa-telegram" style="color: #74c0fc"></i>
      </a>
      <a
        href="https://www.linkedin.com/in/hout-ky-sopanha-8597852ba/"
        target="blank"
      >
        <i class="fa-brands fa-linkedin" style="color: #327bb3"></i>
      </a>
      <a href="tel: +855 99 654 752" target="blank">
        <i class="fa-solid fa-phone" style="color: green"></i>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "ContactFoot",
};
</script>
<style scoped>
body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100vh;
  background-color: #fefefe;
}

i {
  opacity: 0;
  font-size: 28px;
  color: #1f1e1e;
  will-change: transform;
  -webkit-transform: scale(0.1);
  transform: scale(0.1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn_wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  width: 240px;
  height: 50px;
  background-color: whitesmoke;
  /* border: 0.2px solid lightblue; */
  border-radius: 80px;
  padding: 0 18px;
  will-change: transform;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.btn_wrap:hover {
  /* transition-delay: .4s; */
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

span {
  position: absolute;
  z-index: 99;
  width: 240px;
  height: 50px;
  border-radius: 80px;
  font-size: 20px;
  text-align: center;
  line-height: 49px;
  font-weight: bold;
  letter-spacing: 2px;
  color: whitesmoke;
  background-color: lightseagreen;
  padding: 0 18px;
  -webkit-transition: all 1.2s ease;
  transition: all 1.2s ease;
}

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 240px;
  height: 64px;
  border-radius: 80px;
}

.container i:nth-of-type(1) {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.container i:nth-of-type(2) {
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

.container i:nth-of-type(3) {
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

.container i:nth-of-type(4) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.btn_wrap:hover span {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
  -webkit-transform: translateX(-280px);
  transform: translateX(-280px);
}

.btn_wrap:hover i {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.dr {
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 100px;
}
</style>
